<template>
    <div class="create_container">
        <div class="create_title">
            <h2>Questionnaire</h2>
        </div>
        <div class="create_divider">
            <hr style="color: lightgray"/>
        </div>
        <div class="create_body">
            <div class="input_domain" v-if="currentPage===-1">
                <div class="question_subject">
                    Have you participated in any project on this platform or other open source projects?
                </div>
                <div class="question_annotation">We have prepared different questionnaires for different categories of
                    users.
                </div>
                <div class="question_annotation">
                    Depending on the choice of this question, you will enter different questionnaires.
                </div>
                <div class="question_options">
                    <div>
                        <label>
                            <input type="radio" value="0" v-model="questionnaireType">
                            I have not been involved in any open source projects.
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="radio" value="1" v-model="questionnaireType">
                            I have participated in projects on this platform.
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="radio" value="2" v-model="questionnaireType">
                            I have participated in projects on other open source platform.
                        </label>
                    </div>
                </div>
            </div>
            <div class="input_domain" v-if="currentPage>-1"
                 v-for="(item,index) in questionnaire[questionnaireType][currentPage]" :key="index">
                <div class="question_subject">{{currentPage*3+index+1}}. {{item.subject}}</div>
                <div class="question_options" v-if="item.type===1">
                    <div v-for="(item2,index2) in item.options" :key="index2">
                        <label>
                            <input type="radio" :value="index2"
                                   v-model="questionnaireResult[questionnaireType][currentPage*3+index]">
                            {{item2}}
                        </label>
                    </div>
                </div>

                <div class="question_options" v-else-if="item.type===2">
                    <div v-for="(item2,index2) in item.options" :key="index2">

                        <label v-if="item2==='Others'">
                            <input type="checkbox" :value="index2"
                                   v-model="questionnaireResult[questionnaireType][currentPage*3+index]">
                            {{item2}}<input class="other_note" v-model="questionnaireNote"></input>
                        </label>

                        <label v-else>
                            <input type="checkbox" :value="index2"
                                   v-model="questionnaireResult[questionnaireType][currentPage*3+index]">
                            {{item2}}
                        </label>
                    </div>
                </div>
            </div>

            <div v-show="unchonsen" class="unchosen_notice">
                You have unselected questions.
            </div>

            <div class="question_button_container">
                <div style="float:left; margin-left: 10%;width: 20%" v-show="currentPage>-1">
                    <Button style="margin-left: 80px" @click="lastPage">Last</Button>
                </div>
                <div style="float:right; margin-left: 10%;width: 20%"
                     v-if="currentPage < questionnaire[questionnaireType].length-1">
                    <Button style="margin-left: 80px" @click="nextPage">Next</Button>
                </div>
                <div style="float:right; margin-left: 10%;width: 20%" v-else>
                    <Button type="primary" style="margin-left: 80px" @click="submitQuestionnaire">Submit</Button>
                </div>
            </div>
        </div>

<!--        <div>-->
<!--            questionnaireType:{{questionnaireType}}<br>-->
<!--            currentPage:{{currentPage}}<br>-->
<!--            questionResult:{{questionnaireResult}}<br>-->
<!--            others:{{questionnaireNote}}-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "questionnaire",
        data() {
            return {
                unchonsen: false,
                questionnaireType: 0,
                currentPage: -1,
                questionnaireResult: [
                    [-1, -1, -1, -1, [], -1, -1, -1, -1],
                    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
                    [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1],

                ],
                questionnaireNote: " ",
                questionnaire: [
                    [
                        [
                            {
                                subject: "Have you ever used git and other version control tools?",
                                options: ["Yes", "No"],
                                type: 1,
                            },
                            {
                                subject: "Are you satisfied with your current income?",
                                options: ["Yes", "No"],
                                type: 1,
                            },
                            {
                                subject: "Do you believe that it is a good idea to obtain reasonable profits from the contribution to an open-source project?",
                                options: ["Yes", "No"],
                                type: 1,
                            }
                        ],
                        [
                            {
                                subject: "How long is it acceptable for you to adapt and learn the skills of a new useful platform?",
                                options: ["Less than 15 minutes", "0.25 to 1 hour", "several (<= 10) hours",
                                    "Less than a day", "Less than a week", "A few (< 3) months", "1 year or more"],
                                type: 1,
                            },
                            {
                                subject: "What do you think you should get from participating in open source projects? (multiple choice)",
                                options: ["Professional ability", "The sense of belonging", "Learning and communication opportunities", "Reputation", "A certain benefit", "Others"],
                                type: 2,
                            },
                            {
                                subject: "Do you like the business model of investing in source code that earlier investment leads to higher benefits?",
                                options: ["Yes", "No"],
                                type: 1,
                            }
                        ],
                        [
                            {
                                subject: "Your education level:",
                                options: ["Primary school or below", "Middle school or high school", "B.S", "M.S", "Ph.D"],
                                type: 1,
                            },
                            {
                                subject: "Your age: ",
                                options: ["Under 18 years old", "18~26 years old", "26~35 years old", "Over 35 years old"],
                                type: 1,
                            },
                            {
                                subject: "Your occupation: ",
                                options: ["IT and related industry practitioners", "Students", "Freelance", "Other professional"],
                                type: 1,
                            },
                        ]
                    ],
                    [
                        [
                            {
                                subject: "How familiar are you with the platform?",
                                options: ["Hardly able to use", "Often get stuck", "Familiar with basic features", "Know many details", "Familiar with everything"],
                                type: 1,
                            },
                            {
                                subject: "How much time does it to take you for become proficient in our platform?",
                                options: ["Less than 15 minutes", "0.25 to 1 hour", "several (<= 10) hours",
                                    "Less than a day", "Less than a week", "A few (< 3) months", "1 year or more"],
                                type: 1,
                            },
                            {
                                subject: "I am more than happy to continue my work on the platform.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            }
                        ],
                        [
                            {
                                subject: "How many project owners have I communicated with in the last month?",
                                options: ["<=3", "4~10", "10~25", "25~60", "60~200", ">=200"],
                                type: 1,
                            },
                            {
                                subject: "How many people in the platform have I communicated with in the last month?",
                                options: ["<=3", "4~10", "10~25", "25~60", "60~200", ">=200"],
                                type: 1,
                            },
                            {
                                subject: "When I need experience or technical support, I can easily get support from other people on the platform.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "I can solve the membership problem well through the platform or other channels.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "In my project, most members interact closely.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "Is it convenient for us to communicate with other technicians on this platform?",
                                options: ["Yes", "No"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "Is the platform helpful for focusing more on the techniques of your projects?",
                                options: ["Very helpful", " A little helpful", "Not helpful"],
                                type: 1,
                            },
                            {
                                subject: "Do you feel the platform yields high efficiency in development?",
                                options: ["Yes", "Not sure", "No"],
                                type: 1,
                            },
                            {
                                subject: "How many people apply for an enrollment to the projects you have paticipated in?",
                                options: ["<=5", "6~15", "16~50", "51~200", "200~999", "More than 1000"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "Is the process of enrollment convenient?",
                                options: ["Yes, very convenient", "Quite convenient", "Fair", "Not convenient", "Terrible"],
                                type: 1,
                            },
                            {
                                subject: "Compared to other open-source platforms, did you get more profits in this contribution-based model for every code line?",
                                options: ["Yes", "No"],
                                type: 1,
                            },
                            {
                                subject: "I am satisfied with my income in this platform.",
                                options: ["Agree", "Not sure", "Not agree"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "With the help of this platform, do you think your project becomes profitable?",
                                options: ["Yes", "Not sure", "No"],
                                type: 1,
                            },
                            {
                                subject: "There is no malicious resistance between the project owner and the applicant in the platform.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "Do you believe that developers can hardly introduce malicious code intensionally with our contribution-based platform?",
                                options: ["Yes, the contribution-based platform reduces malicious code", "Not Sure", "No, the contribution-based platform even increases malicious code"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "The reliability of co-developed software is good. ",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "The maintainability of co-developed software is good.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "Compared to other open-source platforms, do you believe the projects on this platform are more sustainable?",
                                options: ["Yes", "Not sure", "No"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "Participating in such an open source project can show my professional skills and enhance my reputation, as well as gain some income.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "The current platform is of great personal significance to me.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "Your education level:",
                                options: ["Primary school or below", "Middle school or high school", "B.S", "M.S", "Ph.D"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "Your age: ",
                                options: ["Under 18 years old", "18~26 years old", "26~35 years old", "Over 35 years old"],
                                type: 1,
                            },
                            {
                                subject: "Your occupation: ",
                                options: ["IT and related industry practitioners", "Students", "Freelance", "Other professional"],
                                type: 1,
                            },
                        ]
                    ],
                    [
                        [
                            {
                                subject: "How long have you been involved in the open-source world?",
                                options: ["Less than 6 months", "6 months~1 year", "1~3 years", "3~5 years", "5~10 years", "10~25 years", "More than 25 years"],
                                type: 1,
                            },
                            {
                                subject: "Compared to closed-source projects, do you think open-source projects derive less malicious code?",
                                options: ["Yes", "Not sure", "No"],
                                type: 1,
                            },
                            {
                                subject: "What is the number of open-source project members that I often communicate with?",
                                options: ["0~3", "4~7", "8~13", "14~18", "18~22"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "It is convenient for us to communicate with other technicians on open-source projects. So I don't think the platform needs to consider this aspect of design at present.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "When I need experience or technical support, I can easily get support from other people on other open-source platforms. So I don't think the platform needs to consider this aspect of design at present.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "I hope to solve the membership problem through the platform or other channels better.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "I hope that in the project I work in, most of the members will be more interactive.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "I hope that in the project I work in, it is easier for new participants to enter.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "Compared to closed-source projects, do you think open-source projects derive higher code quality?",
                                options: ["Yes, because every code line can be examined by an open community.", "Not sure, because I cannot access closed source projects.", "No, because free lunch derives low quality"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "I hope to get more income from the open-source development.",
                                options: ["Agree", "Not agree", "Disagree"],
                                type: 1,
                            },
                            {
                                subject: "Do you believe that it is a good idea to obtain reasonable profits from the contribution to an open-source project?",
                                options: ["Yes", "No"],
                                type: 1,
                            },
                            {
                                subject: "How much do you expect to be paid for working as a full-time developer?",
                                options: ["<= 20 USD/hour", "20~50 USD/hour", "50~100 USD/hour", "100~200 USD/hour", "200~500 USD/hour", "500~1000 USD", ">=1000 USD/hour"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "How much do you expect to be paid for working as a part-time developer?",
                                options: ["<= 20 USD/hour", "20~50 USD/hour", "50~100 USD/hour", "100~200 USD/hour", "200~500 USD/hour", "500~1000 USD", ">=1000 USD/hour"],
                                type: 1,
                            },
                            {
                                subject: "Do you think you need revenue from open source projects to improve your standard of living?",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                            {
                                subject: "How much is the total cost do you think of developing latest version of Microsoft Word?",
                                options: ["100~2,000 USD", "2,000~10,000 USD", "10,000~100,000 USD", "100,000~1,000,000 USD", "1,000,000~10,000,000 USD", "More than 10,000,000 USD"],
                                type: 1,
                            },

                        ],
                        [
                            {
                                subject: "How much is a reasonable price for selling a personal copy of Microsoft Word?",
                                options: ["20~40 USD", "40~100 USD", "100~300 USD", "300~1,000 USD", "1,000~5,000 USD", "5,000~20,000 USD", "More than 20,000 USD"],
                                type: 1,
                            },
                            {
                                subject: "How many users do you think will buy a software similar to Microsoft Word?",
                                options: ["<=10", "10~100", "100~1,000", "1,000~20,000", "20,000~400,000", "400,000~20,000,000", "20,000,000~200,000,000", "More than 200,000,000"],
                                type: 1,
                            },
                            {
                                subject: "Participating in such an open source project can show my professional skills and enhance my reputation.",
                                options: ["Totally agree", "Agree", "Not sure", "Not agree", "Totally disagree"],
                                type: 1,
                            },
                        ],
                        [
                            {
                                subject: "Your education level:",
                                options: ["Primary school or below", "Middle school or high school", "B.S", "M.S", "Ph.D"],
                                type: 1,
                            },
                            {
                                subject: "Your age: ",
                                options: ["Under 18 years old", "18~26 years old", "26~35 years old", "Over 35 years old"],
                                type: 1,
                            },
                            {
                                subject: "Your occupation: ",
                                options: ["IT and related industry practitioners", "Students", "Freelance", "Other professional"],
                                type: 1,
                            },
                        ]
                    ]
                ]
            };
        },
        methods: {
            initialData() {
                let questionnaireStore = this.$cookies.get("questionnaireStore");
                if (questionnaireStore != null) {
                    this.questionnaireType = questionnaireStore["questionnaireType"];
                    this.questionnaireResult = questionnaireStore["questionnaireResult"];
                    this.questionnaireNote = questionnaireStore["questionnaireNote"];

                    if (typeof (this.questionnaireResult[0][4]) === "string") {
                        let temp = this.questionnaireResult[0][4].split(";");
                        let tep = []
                        for (let i = 0; i < temp.length; i++) {
                            if (temp[i] !== null && temp[i] !== " ") {
                                tep.push(parseInt(temp[i]))
                            }
                        }
                        tep.sort()
                        this.questionnaireResult[0][4] = tep
                    }
                    return
                }
                var Tself = this;
                let user = this.getCookie("user_id");
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: "/questionnaire/findByUserId",
                    data: user
                }).then(function (response) {
                    let data = response.data.data.items;
                    if (data == null) {
                        return
                    }
                    let questionnaireType = data.questionnaireType;
                    let questionnaireResult = data.questionnaireResult;

                    let resultlist = [];
                    resultlist = questionnaireResult.split(",")

                    for (let i = 0; i < resultlist.length; i++) {
                        if (questionnaireType == 0 && i === 4) {
                            resultlist[4] = resultlist[4].split(";")
                            Tself.questionnaireNote = resultlist[4].pop()
                        } else {
                            resultlist[i] = parseInt(resultlist[i])
                        }
                    }


                    Tself.questionnaireType = parseInt(questionnaireType);
                    Tself.questionnaireResult[questionnaireType] = resultlist;

                }).catch(function (error) {
                    console.log(error.message);
                });
            },
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            lastPage() {
                if (this.currentPage > -1) {
                    this.currentPage--
                    this.unchonsen = false;
                }
            },
            nextPage() {
                if (this.currentPage < this.questionnaire[this.questionnaireType].length - 1) {
                    if (this.currentPage === -1) {
                        this.currentPage++;
                        return
                    }
                    if (this.questionnaireType == 0 && this.currentPage === 0) {
                        if (this.questionnaireResult[0][0] === -1 || this.questionnaireResult[0][2] === -1 || this.questionnaireResult[0][1].length === 0) {
                            this.unchonsen = true;
                            this.$Message.error("You have unselected questions.");
                        } else {
                            this.unchonsen = false;
                            this.currentPage++;
                        }
                    } else {
                        let unchosen = false;
                        for (let i = 0; i < this.questionnaire[this.questionnaireType][this.currentPage].length; i++) {
                            if (this.questionnaireResult[this.questionnaireType][this.currentPage * 3 + i] === -1) {
                                unchosen = true;
                                break;
                            }
                        }
                        if (unchosen === true) {
                            this.unchonsen = true;
                            this.$Message.error("You have unselected questions.");
                        } else {
                            this.unchonsen = false;
                            this.currentPage++
                        }
                    }
                }
            },
            storeResult() {
                let questionnaireStore = {
                    "questionnaireType": this.questionnaireType,
                    "questionnaireResult": this.questionnaireResult,
                    "questionnaireNote": this.questionnaireNote,
                }
                this.$cookies.set("questionnaireStore", JSON.stringify(questionnaireStore));
            },
            submitQuestionnaire() {
                let unchosen = false;
                for (let i = 0; i < this.questionnaire[this.questionnaireType][this.currentPage].length; i++) {
                    if (this.questionnaireResult[this.questionnaireType][this.currentPage * 3 + i] === -1) {
                        unchosen = true;
                        break;
                    }
                }
                if (unchosen === true) {
                    this.unchonsen = true;
                    this.$Message.error("You have unselected questions.");
                    return
                } else {
                    this.unchonsen = false;
                }

                let questionnaireResult;
                let user = this.getCookie("user_id");

                if (user === null || user === undefined || user === '') {
                    this.$Message.error("You are not logged in.");
                    this.$router.push("/Login");
                    return;
                }

                if (this.questionnaireType == 0) {
                    questionnaireResult = this.deepClone(this.questionnaireResult[0]);
                    questionnaireResult[4].sort()
                    questionnaireResult[4].push(this.questionnaireNote)
                    questionnaireResult[4] = questionnaireResult[4].join(";")

                } else {
                    questionnaireResult = this.questionnaireResult[this.questionnaireType]
                }
                questionnaireResult = questionnaireResult.join(",")


                this.$axios.post(
                    "/questionnaire/submitQuestionnaire",
                    {
                        user: user,
                        questionnaireType: this.questionnaireType,
                        questionnaireResult: questionnaireResult,
                    }
                ).then((response) => {
                    this.$Message.success('You have successfully completed the questionnaire.');
                    this.$router.push("/");
                }).catch((error) => {
                    this.$Message.error(error.message);
                })
            },
            deepClone(obj) {
                //判断拷贝的要进行深拷贝的是数组还是对象，是数组的话进行数组拷贝，对象的话进行对象拷贝
                var objClone = Array.isArray(obj) ? [] : {};
                //进行深拷贝的不能为空，并且是对象或者是
                if (obj && typeof obj === "object") {
                    for (let key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            if (obj[key] && typeof obj[key] === "object") {
                                objClone[key] = this.deepClone(obj[key]);
                            } else {
                                objClone[key] = obj[key];
                            }
                        }
                    }
                }
                return objClone;
            },
        },
        mounted() {
            this.initialData();
        },
        destroyed() {
            this.storeResult();
        }
    }
</script>

<style scoped>
    .create_container {
        width: 100%;
        height: auto;
        background-color: white;
    }

    .create_title {
        height: 50px;
        float: left;
        width: 100%;
        padding: 20px 15%;
        text-align: left;
    }

    .create_body {
        width: 100%;
        padding: 10px 16%;
        text-align: left;

    }

    .create_divider {
        float: left;
        color: lightgray;
        height: 1px;
        width: 80%;
        margin-left: 13%;
        margin-top: 8px;
        margin-bottom: 17px;

        clear: both;
    }

    .input_domain {
        width: 100%;
        margin-bottom: 16px;
    }

    .question_subject {
        font-size: 18px;
        margin-bottom: 4px;
    }

    .question_annotation {
        color: #2db7f5;
    }

    .question_options {
        font-size: 15px;
        margin: 8px;

    }

    .question_button_container {
        float: left;
        padding: 10px 20%;
        height: 50px;
        width: auto;
    }

    .unchosen_notice {
        color: red;
        font-size: 20px;
        font-weight: bold;
    }

    .other_note {
        margin-left: 5px;
        padding-left: 5px;
        border-bottom: 1px solid #005aa7; /* 下划线效果 */
        border-top: 0;
        border-left: 0;
        border-right: 0;
        background-color: transparent; /* 背景色透明 */
    }


</style>